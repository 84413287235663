@font-face {
  font-family: 'Archivo';
  src: local('Archivo'), url(./fonts/Archivo-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'ArchivoBold';
  src: local('ArchivoBold'), url(./fonts/Archivo-Bold.ttf) format('truetype');
}

:root {
  --main-bg-color0: #FFFFFF;
  --main-bg-color1: #4274BA;
  --main-bg-color2: #FB8303;
  --main-bg-color3: #3679E9;
  --main-bg-color4: #679635;
  --main-bg-color5: #F50A00;
  --main-list-color0: #4274BA;
  --main-list-color1: #e9e9e9;
  --main-hover-color0: #FB8303;
  --main-hover-color1: #777475;
  --main-text-color0: #777475;
  --main-text-color1: #FFFFFF;
  --main-text-color2: #4274BA;
}

.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}